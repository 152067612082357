import React, { useState } from "react";
import ScrollToTop from "../../../../ScrollToTop";
import NavbarDashboard from "../../../NavbarDashboard";
import Tabs from "react-bootstrap/Tabs";
import { useHistory, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import StepCategory from "./Categories/StepCategory";
import RadioCheck from "./Categories/RadioCheck";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInspectionStepsApi,
  getInspectionStepsCategoryChecksApi,
} from "../../../../../redux/reducers/inspectionSlice";
import axios from "axios";
import DialogBox from "../../DialogBox";
import ViewPopup from "./ViewPopup";
import CategoryCheck from "./Categories/CategoryCheck";

const InspectionStepData = () => {
  const MobileCheck = window.screen.availWidth < 600

  const dispatch = useDispatch();
  const history = useHistory();
  const { id, bookmark_id } = useParams();
  const { name: stepName } = useParams();
  const [tabKey, setTabKey] = useState("description");
  var token = localStorage.getItem("access_token");

  const [addCategoryValue, setAddCategoryValue] = useState("");

  const [showDialog, setShowDialog] = useState(false);
  const [dialogFor, setDialogFor] = useState();
  const [deleteLabel, setDeleteLabel] = useState();
  const [categoryValue, setCategoryValue] = useState();
  const [oldEditCateValue, setOldEditCateValue] = useState();
  const [showVeiwPopup, setShowVeiwPopup] = useState(false);

  const [commentData, setCommentData] = useState({});
  const [activeCategory, setActiveCategory] = useState(null)
  const handleClose = () => {
    setShowDialog(false);
    setAddCategoryValue("");
  };
  const handleShow = () => setShowDialog(true);

  const handleViewPopupClose = () => {
    setShowVeiwPopup(false);
  };
  const handleViewPopupShow = () => {
    setShowVeiwPopup(true);
  };

  const inspectionStepData = useSelector(
    (state) => state.inspection.storeActiveInspectionStep
  );
  const is_applicable = inspectionStepData?.data?.is_applicable;
  const [notApplicableChecked, setNotApplicableChecked] = useState();
  const inspectionDescription = inspectionStepData.data?.description;
  const inspectionObservation = inspectionStepData.data?.observation;
  const inspectionRecomendation = inspectionStepData.data?.recomendation;
  const inspectionStepChecks = inspectionStepData?.checksDataFor?.data?.checks;
  const inspectionStepTab = inspectionStepData?.checksDataFor?.tabName;

  const [activeCategoryStep, setActiveCategoryStep] = useState(null);

  useEffect(() => {
    dispatch(getInspectionStepsApi({ id, stepName, bookmark_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNotApplicableChecked(!is_applicable);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_applicable]);

  const handleInspectionTabContent = (key) => {
    setTabKey(key);

    let tabKeyName;
    let firstIndexToGetChecks;
    if (key === "description") {
      tabKeyName = key;
      firstIndexToGetChecks = inspectionDescription[0]?.id;
    } else if (key === "observation") {
      tabKeyName = key;
      firstIndexToGetChecks = inspectionObservation[0]?.id;
    } else if (key === "recommendation") {
      tabKeyName = key;
      firstIndexToGetChecks = inspectionRecomendation[0]?.id;
    }

    dispatch(
      getInspectionStepsCategoryChecksApi({
        id,
        stepName,
        categoryName: firstIndexToGetChecks,
        tabName: tabKeyName,
      })
    );

    setActiveCategoryStep(firstIndexToGetChecks);
  };

  const handleActiveCategoryStep = (categoryName, tabName) => {
    setActiveCategory(categoryName)
    handleGetInspectionChecks(categoryName, tabName);
    setActiveCategoryStep(categoryName);
  };

  const handleGetInspectionChecks = (getCategoryName, tabName) => {
    dispatch(
      getInspectionStepsCategoryChecksApi({
        id,
        stepName,
        categoryName: getCategoryName,
        tabName,
      })
    );
  };

  const handleChangeInRadio = async (check, label) => {
    const ourUpdatedCheckObj = [{ label: label, checked: check }];
    const newArrOfObj = [...inspectionStepChecks];
    // const radioToBeUpdated = newArrOfObj.findIndex(checks => checks.label === label)
    const radiocheckedArray = newArrOfObj.map(checks => {
      let myObj = { ...checks }
      myObj.checked = false
      return myObj
    })
    const freshData = radiocheckedArray.map(
      (obj) => ourUpdatedCheckObj.find((o) => o.label === obj.label) || obj
    );

    document.body.classList.add("loading-indicator");

    var data = {
      inspection_id: id,
      step_name: inspectionStepData?.name,
      category: inspectionStepData?.checksDataFor?.categoryName,
      checks: freshData,
    };
    var changeInCheckboxData = {
      method: "post",
      url: "/v1/oauth/inspection/update-category-checks",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    await axios(changeInCheckboxData)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              stepName,
              categoryName: inspectionStepData?.checksDataFor?.categoryName,
              tabName: inspectionStepTab,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeInCheckbox = async (check, label) => {
    const ourUpdatedCheckObj = [{ label: label, checked: check }];
    const newArrOfObj = [...inspectionStepChecks];
    const freshData = newArrOfObj.map(
      (obj) => ourUpdatedCheckObj.find((o) => o.label === obj.label) || obj
    );

    document.body.classList.add("loading-indicator");

    var data = {
      inspection_id: id,
      step_name: inspectionStepData?.name,
      category: inspectionStepData?.checksDataFor?.categoryName,
      checks: freshData,
    };
    var changeInCheckboxData = {
      method: "post",
      url: "/v1/oauth/inspection/update-category-checks",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    await axios(changeInCheckboxData)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              stepName,
              categoryName: inspectionStepData?.checksDataFor?.categoryName,
              tabName: inspectionStepTab,
            })
          );
        }
        document.body.classList.remove("loading-indicator");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetCategoryLabel = (editLabel) => {
    setCategoryValue(editLabel);
    setOldEditCateValue(editLabel);
    setDialogFor("edit");
    handleShow();
  };

  const handleOpenCategoryCreationModal = () => {
    setDialogFor("add");
    handleShow();
  };

  const handleCategoryCreation = () => {
    const formData = {
      inspection_id: id,
      step_name: stepName,
      new_label: addCategoryValue,
      category: inspectionStepData?.checksDataFor?.categoryName,
    };
    const configValues = {
      method: "post",
      url: "/v1/oauth/inspection/add-label",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(configValues)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              stepName,
              categoryName: inspectionStepData?.checksDataFor?.categoryName,
              tabName: inspectionStepTab,
            })
          );
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCategoryUpdation = () => {
    const formData = {
      inspection_id: id,
      step_name: stepName,
      new_value: categoryValue,
      old_value: oldEditCateValue,
      category: inspectionStepData?.checksDataFor?.categoryName,
    };
    const configValues = {
      method: "post",
      url: "/v1/oauth/inspection/update-label",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(configValues)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              stepName,
              categoryName: inspectionStepData?.checksDataFor?.categoryName,
              tabName: inspectionStepTab,
            })
          );
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOpenCategoryDeletionModal = (label) => {
    setDialogFor("delete");
    setDeleteLabel(label);
    handleShow();
  };
  const handleCategoryDeletion = () => {

    const formData = {
      inspection_id: id,
      step_name: stepName,
      label: deleteLabel,
      category: inspectionStepData?.checksDataFor?.categoryName,
    };
    const configValues = {
      method: "post",
      url: "/v1/oauth/inspection/delete-label",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(configValues)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              stepName,
              categoryName: inspectionStepData?.checksDataFor?.categoryName,
              tabName: inspectionStepTab,
            })
          );
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleViewPopupGetValues = (values) => {
    document.body.classList.add("loading-indicator");
    var requiredVals = {
      inspection_id: id,
      step_name: stepName,
      category: inspectionStepData?.checksDataFor?.categoryName,
      label: values.label,
    };
    const configAxios = {
      method: "post",
      url: "v1/oauth/inspection/get-comment-images",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: requiredVals,
    };
    axios(configAxios)
      .then((res) => {
        if (res.data.status === true) {
          const resData = res.data;
          const data = { resData, values };
          setCommentData(data);
          document.body.classList.remove("loading-indicator");
          handleViewPopupShow();
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
      });
  };
  const handlePopupCommentImageApi = (data) => {
    let images = data.uploadImages
    document.body.classList.add("loading-indicator");
    var formDataValues = new FormData();
    formDataValues.append("selrecomd", data.radioChecked);
    formDataValues.append("inspection_id", id);
    formDataValues.append("step_name", stepName);
    formDataValues.append(
      "category",
      inspectionStepData.checksDataFor.categoryName
    );
    formDataValues.append("label", data.label);
    formDataValues.append("checked", data.checked);
    formDataValues.append("comment", data.comment);
    formDataValues.append("call_from", "web")
    for (var i = 0; i < images.length; i++) {
      formDataValues.append('images[]', images[i]);
    }

    const config = {
      method: "post",
      url: "/v1/oauth/inspection/save-comment-images",
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      data: formDataValues,
    };
    axios(config)
      .then((res) => {
        if (res.data.status === true) {
          document.body.classList.remove("loading-indicator");
          handleViewPopupClose();
          if (data.radioChecked.length > 0) {
            dispatch(
              getInspectionStepsCategoryChecksApi({
                id,
                stepName,
                categoryName: inspectionStepData?.checksDataFor?.categoryName,
                tabName: inspectionStepTab,
              })
            );
            // handleChangeInCheckbox(true, data.label)
          }
        } else if (res.data.status === false) {
          document.body.classList.remove("loading-indicator");
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");

        console.log(err);
      });
  };

  const handleIfApplicable = () => {
    document.body.classList.add("loading-indicator");
    const values = {
      inspection_id: id,
      step_name: stepName,
    };
    const configData = {
      method: "post",
      url: "/v1/oauth/inspection/not-applicable",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    };
    axios(configData)
      .then((res) => {
        if (res.data.status === true) {
          document.body.classList.remove("loading-indicator");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />

      <div className="content-container Mcontent ">
        <div className="side-spacing pt50 pb50 ">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="float-start no-shadow pt-1 btn"
                onClick={() => history.goBack()}
              >
                <img
                  src="/assets/arrow-fwd-lg.png"
                  style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                  alt=""
                />
              </button>
              <h2 className="mb-0 ml20 text-transform-capitalize">
                {inspectionStepData.name === "portfolio"
                  ? "Structure"
                  : inspectionStepData.name}
              </h2>
            </div>

            <label className="cursor-pointer">
              <input
                type="checkbox"
                defaultChecked={notApplicableChecked}
                className="mr10 form-check-input"
                onClick={handleIfApplicable}
              />
              Not Applicable
            </label>
          </div>

          <br />
          <br />
          <Tabs
            activeKey={tabKey}
            onSelect={(k) => handleInspectionTabContent(k)}
            transition={false}
            id="noanim-tab-example"
            className="mb-3 insp-tabs"
          >
            <Tab
              eventKey={"description"}
              title={
                inspectionStepData.name === "portfolio"
                  ? "Description of Structure"
                  : `Description of ${inspectionStepData.name}`
              }
              className="mt50"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {inspectionDescription &&
                      inspectionDescription?.length > 0 &&
                      inspectionDescription.map((data) => {
                        return (
                          <StepCategory
                            key={data.id}
                            data={data}
                            handleActiveCategoryStep={handleActiveCategoryStep}
                            tabName="description"
                            handleOpenCategoryCreationModal={
                              handleOpenCategoryCreationModal
                            }
                            activeCategoryStep={activeCategoryStep}
                            inspectionStepChecks={inspectionStepChecks}
                            activeCategory={activeCategory}
                            MobileCheck={MobileCheck}
                            handleChangeInCheckbox={handleChangeInCheckbox}
                            handleGetCategoryLabel={handleGetCategoryLabel}
                            handleOpenCategoryDeletionModal={
                              handleOpenCategoryDeletionModal
                            }
                          />
                        )
                      }
                      )}

                  </div>
                </div>
                {!MobileCheck && (


                  <div className="col-md-6">
                    <>
                      {inspectionStepChecks &&
                        inspectionStepTab === "description" &&
                        inspectionStepChecks.map((check, index) => (
                          <CategoryCheck
                            key={index}
                            data={check}
                            handleChangeInCheckbox={handleChangeInCheckbox}
                            handleGetCategoryLabel={handleGetCategoryLabel}
                            tabName="description"
                            handleOpenCategoryDeletionModal={
                              handleOpenCategoryDeletionModal
                            }
                          />
                        ))}
                      {/* <div><button style={{ width: "60%" }} className="btn-submit p5 br4">
                      Save Changes
                    </button></div> */}
                    </>
                  </div>
                )}

              </div>

            </Tab>

            <Tab
              eventKey={"observation"}
              title={
                inspectionStepData.name === "portfolio"
                  ? "Structure Observation"
                  : `${inspectionStepData.name} Observation`
              }
              className="mt50 "
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {inspectionObservation &&
                      inspectionObservation.map((data, index) => (
                        <StepCategory
                          key={data.id}
                          data={data}
                          handleActiveCategoryStep={handleActiveCategoryStep}
                          handleOpenCategoryCreationModal={
                            handleOpenCategoryCreationModal
                          }
                          tabName="observation"
                          activeCategoryStep={activeCategoryStep}
                        />
                      ))}

                  </div>
                </div>
                <div className="col-md-6">
                  {inspectionStepChecks &&
                    inspectionStepTab === "observation" &&
                    inspectionStepChecks.map((check, index) => (
                      <RadioCheck
                        key={index}
                        data={check}
                        handleChangeInRadio={handleChangeInRadio}
                        handleViewPopupGetValues={handleViewPopupGetValues}
                        tabName="observation"
                        handleGetCategoryLabel={handleGetCategoryLabel}
                        handleOpenCategoryDeletionModal={
                          handleOpenCategoryDeletionModal
                        }
                      />
                    ))}
                </div>
              </div>
            </Tab>

            <Tab
              eventKey={"recommendation"}
              title={
                inspectionStepData.name === "portfolio"
                  ? "Recommendations of Structure"
                  : `Recommendations of ${inspectionStepData.name}`
              }
              className="mt50 "
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {inspectionRecomendation &&
                      inspectionRecomendation.map((data, index) => (
                        <StepCategory
                          key={index}
                          data={data}
                          MobileCheck={MobileCheck}
                          handleActiveCategoryStep={handleActiveCategoryStep}
                          handleOpenCategoryCreationModal={
                            handleOpenCategoryCreationModal
                          }
                          tabName="recommendation"
                          activeCategoryStep={activeCategoryStep}
                          inspectionStepChecks={inspectionStepChecks}
                          activeCategory={activeCategory}
                          handleChangeInCheckbox={handleChangeInCheckbox}
                          handleGetCategoryLabel={handleGetCategoryLabel}
                          handleViewPopupGetValues={handleViewPopupGetValues}
                          handleOpenCategoryDeletionModal={
                            handleOpenCategoryDeletionModal
                          }
                        />
                      ))}
                  </div>
                </div>
                {!MobileCheck && (
                  <div className="col-md-6">
                    {inspectionStepChecks &&
                      inspectionStepTab === "recommendation" &&
                      inspectionStepChecks.map((check, index) => (
                        <CategoryCheck
                          key={index}
                          data={check}
                          handleChangeInCheckbox={handleChangeInCheckbox}
                          handleGetCategoryLabel={handleGetCategoryLabel}
                          tabName="recommendation"
                          handleViewPopupGetValues={handleViewPopupGetValues}
                          handleOpenCategoryDeletionModal={
                            handleOpenCategoryDeletionModal
                          }
                        />
                      ))}


                  </div>
                )}
              </div>
            </Tab>
          </Tabs>

        </div>
      </div>
      {dialogFor === "add" ? (
        <DialogBox
          title="Add Category"
          inputLabel="Enter Category Name"
          inputType="text"
          inputName="add_category"
          placeholder="Enter name"
          categoryValue={addCategoryValue}
          setCategoryValue={setAddCategoryValue}
          handleCategoryApi={handleCategoryCreation}
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : dialogFor === "edit" ? (
        <DialogBox
          title="Edit Category"
          inputLabel="Update Name"
          inputType="text"
          inputName="update_category"
          placeholder="Enter name"
          categoryValue={categoryValue}
          setCategoryValue={setCategoryValue}
          handleCategoryApi={handleCategoryUpdation}
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : dialogFor === "delete" ? (
        <DialogBox
          title="Delete Category"
          bodyText={`Do you really want to delete ${deleteLabel} `}
          handleCategoryApi={handleCategoryDeletion}
          status="delete"
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : (
        ""
      )}
      {showVeiwPopup && (
        <ViewPopup
          handleClose={handleViewPopupClose}
          show={showVeiwPopup}
          data={commentData}
          tabName={inspectionStepTab}
          handlePopupCommentImageApi={handlePopupCommentImageApi}
        />
      )}
    </>
  );
};

export default InspectionStepData;
