import InspectionBox from "../InspectionBox";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import DialogBox from "../../../utils/DialogBox";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import { inspectionExistence } from "../../../redux/reducers/inspectionSlice";
import useClient from "../../../Hooks/useClient";
import useInspection from "../../../Hooks/useInspection";
import { useSelector } from "react-redux";
import { switchRedirection } from "../../../redux/reducers/inspectionSlice";

const CreateInspection = () => {
  const [query, setQuery] = useState('')
  const [inspection] = useInspection();
  const [clients] = useClient(query);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };
  const inspectionExistance = useSelector(state => state.inspection.checkInspectionExistanceData)
  const inspectionredirection = useSelector(state => state.inspection.redirection)

  useEffect(() => {
    if (inspectionExistance.status === true) {
      var inspection_id;

      if (inspectionExistance.inspection !== null && inspectionExistance.inspection !== undefined) {
        inspection_id = inspectionExistance.inspection.id;
      } else {
        inspection_id = inspectionExistance.inspection_id;
      }
      if (inspection_id === 0) {
        const client_id = inspectionExistance.client.id
        if (inspectionredirection) {
          dispatch(switchRedirection(false))
          history.push(`/property-description/${client_id}`)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionExistance])

  const handleSelectedClient = (id) => {
    if (id === "Select Client") {
      notify("Please select client!");
    } else {
      dispatch(inspectionExistence({ client_id: id, history }));
    }
  };

  return (
    <>
      <div className="create-inspection-ct side-spacing align-items-center">
        <div id="inspection-cards-main">
          <div className="text-ct w-35 pb-5 ">
            <h2>Create Inspection</h2>
            <p>
              Access an inspection that you have already started or create a new inspection.
            </p>
            <button className="new-Ins-btn" onClick={handleShow}>
              <div className="circlePlus"> + </div>
              Create/Resume Inspection
            </button>
          </div>
          {inspection.length > 0 ? (
            <div className="d-flex" id="inspection-cards">
              <div className="ins-box-ct card-Inspection-wd">
                <InspectionBox data={inspection[0]} />
              </div>
              {inspection.length > 1 ? (
                <div className="ins-box-ct card-Inspection-wd">
                  <InspectionBox data={inspection[1]} />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {inspection.length > 0 ? (
          <a
            href="/inspection-list"
            className="view-all-inspection greylight fs16"
          >
            View all Inspections
          </a>
        ) : null}
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {show && (
        <DialogBox
          handleClose={handleClose}
          show={show}
          title="Select Client"
          status="selectClient"
          data={clients}
          handleSelectedClient={handleSelectedClient}
          setQuery={setQuery}
        />
      )}
    </>
  );
};

export default CreateInspection;
