import CategoryCheck from "./CategoryCheck";
const StepCategory = (props) => {

  const { id, label } = props.data;
  const {
    handleActiveCategoryStep,
    activeCategoryStep,
    tabName,
    handleOpenCategoryCreationModal,
    inspectionStepChecks,
    activeCategory,
    MobileCheck,
    handleChangeInCheckbox,
    handleGetCategoryLabel,
    handleOpenCategoryDeletionModal, handleViewPopupGetValues
  } = props;
  let FirstLetter = label[0].toUpperCase();
  let SecondLetter = label[1].toUpperCase();


  const handleIconShow = (id, tabName) => {
    handleActiveCategoryStep(id, tabName);
  };
  const handleAddClick = () => {
    handleOpenCategoryCreationModal();
  };
  return (
    <>
      <div
        className={
          "d-flex align-items-center justify-content-between circle-card-ct cursor-pointer w-100 " +
          (activeCategoryStep === id ? "active" : "")
        }
        id={id}
        onClick={() => handleIconShow(id, tabName)}
      >
        <div className="d-flex align-items-center">
          <div className="float-start">
            <div className="circle-name  mr10">
              <div> {FirstLetter + SecondLetter}</div>
            </div>
          </div>
          <h5 className="mb-0 d-flex break-word">{label}</h5>
        </div>

        {activeCategoryStep === id && (
          <div
            onClick={() => handleAddClick()}
            className={tabName === "observation" ? "hide" : ""}
          >
            <img style={{ height: 40 }} src="/assets/icon/add.png" alt="add category" />
          </div>
        )}
      </div>
      {inspectionStepChecks && MobileCheck && activeCategory === id && (
        inspectionStepChecks.map((check, index) => (
          <CategoryCheck
            key={index}
            data={check}
            tabName={tabName}
            handleChangeInCheckbox={handleChangeInCheckbox}
            handleGetCategoryLabel={handleGetCategoryLabel}
            handleViewPopupGetValues={handleViewPopupGetValues}
            handleOpenCategoryDeletionModal={handleOpenCategoryDeletionModal}
          />
        ))
      )}


    </>
  );
};

export default StepCategory;
