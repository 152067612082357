import { configureStore } from "@reduxjs/toolkit";
import inspectionReducer from './reducers/inspectionSlice'
import notificationReducer from "./reducers/notificationSlice";

export default configureStore({
    reducer: {
        inspection: inspectionReducer,
        notification: notificationReducer
    }

})