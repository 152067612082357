import React from "react";
import { Form } from "react-bootstrap";

const CategoryCheck = (props) => {
  const { checked, label } = props.data;
  const {
    handleChangeInCheckbox,
    tabName,
    handleGetCategoryLabel,
    handleViewPopupGetValues,
    handleOpenCategoryDeletionModal,
  } = props;

  const handleChange = async (e) => {
    if (tabName === "description" || e.target.checked === false) {
      await handleChangeInCheckbox(e.target.checked, label);
    }
    handleViewClick({ label, checked })

  };
  const handleEditClick = (label) => {
    handleGetCategoryLabel(label);
  };
  const handleDeleteClick = (label) => {
    handleOpenCategoryDeletionModal(label);
  };
  const handleViewClick = async (data, other) => {
    if (other) {
      handleViewPopupGetValues(data);
      return
    }
    if (tabName !== "description" && data.checked === false) {
      handleViewPopupGetValues(data);
    }
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb20">
        <Form.Check
          type="checkbox"
          id="default-checkbox"
          name="group1"
          className="custom-formCheck "
          label={label}
          checked={checked}
          onChange={(e) => handleChange(e)}
        />

        <div className="d-flex">
          {tabName === "description" || tabName === "recommendation" ? (
            <div
              className="ml20 cursor-pointer"
              onClick={() => handleEditClick(label)}
            >
              <img src="/assets/edit.png" alt="" />
            </div>
          ) : (
            ""
          )}
          {tabName === "observation" || tabName === "recommendation" ? (
            <div
              className="ml20 cursor-pointer"
              onClick={() => handleViewClick({ label, checked }, "view")}
            >
              <img
                src="/assets/eye.png"
                alt=""
                style={{ height: 20, width: 20 }}
              />
            </div>
          ) : (
            ""
          )}
          {tabName === "description" || tabName === "recommendation" ? (
            <div
              className="ml20 cursor-pointer"
              onClick={() => handleDeleteClick(label)}
            >
              <img
                src="/assets/close.png"
                style={{ height: 20, width: 20 }}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryCheck;
