import React from "react";
import { Form } from "react-bootstrap";

const RadioCheck = (props) => {
  const { checked, label } = props.data;
  const { handleChangeInRadio, handleViewPopupGetValues } = props;
  const handleChange = async (e) => {
    await handleChangeInRadio(e.target.checked, label);
    handleViewClick({ label, checked })
  };
  const handleViewClick = (data) => {
    handleViewPopupGetValues(data);
  };
  return (
    <div className="d-flex align-items-center justify-content-between mb20">
      <Form.Check
        type="radio"
        name="group1"
        // id="default-checkbox"
        // className="custom-formCheck "
        label={label}
        checked={checked}
        onChange={handleChange}
      />

      <div className="d-flex">
        <div
          className="ml20 cursor-pointer"
          onClick={async () => {
            if (checked !== true) {
              await handleChangeInRadio(true, label)
            }
            handleViewClick({ label, checked })
          }}
        >
          <img src="/assets/eye.png" alt="" style={{ height: 20, width: 20 }} />
        </div>
      </div>
    </div>
  );
};

export default RadioCheck;
