import React from 'react'
import {
    slide as Menu
} from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom'

const HamBurger = () => {
    const location = useLocation();
    const pathname = location.pathname;
    return (
        <div><Menu customBurgerIcon={<img src="/assets/icon/menu.png" alt="open" />}
            customCrossIcon={<img src="/assets/icon/exit.png" alt="close" />}
        >
            <Link
                to="/dashboard"
                className={pathname === "/dashboard" ? "active-nav nav-links" : "nav-links"}
            >
                Dashboard
            </Link>
            <Link
                to="/clients"
                className={pathname === "/clients" ? "active-nav nav-links" : "nav-links"}

            >
                Client
            </Link>
            <Link
                to="/inspection"
                className={pathname === "/inspection" ? "active-nav nav-links" : "nav-links"}

            >
                Inspection
            </Link>
            <Link
                to="/inspection-list"
                className={
                    pathname === "/inspection-list" ? "active-nav nav-links" : "nav-links"
                }
            >
                Inspection List
            </Link>
            <Link
                to="/reports"
                className={pathname === "/reports" ? "active-nav nav-links" : "nav-links"}
            >
                Report
            </Link>
            <Link to="/reports-history"
                className={pathname === "/reports-history" ? "active-nav nav-links" : "nav-links"}
            >Report Archives</Link>
            <Link to="/mail-logs"
                className={pathname === "/mail-logs" ? "active-nav nav-links" : "nav-links"}
            >Email Logs</Link>
        </Menu></div>
    )
}

export default HamBurger

